import React, { useState, useEffect } from 'react';
import axios from 'axios';
import jwt_decode from "jwt-decode";
import Navbar from "../../Navbar";
import Header from "../../Header";
import baseUrl from "../../BaseUrl";
import { useNavigate, Link, useLocation } from 'react-router-dom';
import { saveAs } from 'file-saver';


const CreateOrderSuccess = () => {

    const [name, setName] = useState('');
    const [token, setToken] = useState('');
    const [role, setRole] = useState('');
    const [expire, setExpire] = useState('');
    const [access, setAccess] = useState([]);
    const [cancelFailedMessage, setCancelFailedMessage] = useState(false);
    const navigate = useNavigate();
    const location = useLocation();
    const abx = location.state;
    

    useEffect(() => {
        refreshToken();
        getUserAccess();
        // getUsers();
    }, []);

    useEffect(() => {
        saveREsultApi();
        checkCancelMessage();
      }, [abx]);

    
    const saveREsultApi = async () => {
        const response = await axiosJWT.post(`${baseUrl}/save-result`, {data:abx}, {
            headers: {
                Authorization: `Bearer ${token}`
            }       
        });
        console.log(response);
    }

    const refreshToken = async () => {
        try {
            const response = await axios.get(`${baseUrl}/token`);
            
            setToken(response.data.accessToken);
            const decoded = jwt_decode(response.data.accessToken);
            console.log(decoded.exp);
            setName(decoded.name);
            setRole(decoded.role)
            setExpire(decoded.exp);
        } catch (error) {
           
            if (error.response) {
                // setError("Unauthorized");
                navigate("/");  
                window.location.reload();
            }
        }
    }
    const getUserAccess = async () => {
        try {
            const response = await axiosJWT.get(`${baseUrl}/user-access`, {
                headers: {
                    Authorization: `Bearer ${token}`
                }       
            });
           
            console.log(response.data.results);
            setAccess(response.data.results);
            // setIsLoading(false);
            
        } catch (error) {
           console.log(error);
        //    setIsLoading(false);
            // if (error.response) {
            //     // setError("Unauthorized");
            //     navigate("/");  
            //     window.location.reload();
            // }
        }
    }

    const exportErrors = async (e) => {
        e.preventDefault();
        const keysToInclude = Object.keys(abx).filter(key =>{return key !== 'importError' && key !== 'Success' && key !== 'telephoneNumberOrder'});
        
        const telephoneNumberOrder = abx['telephoneNumberOrder']

        const header = 'Tel Number,Status';
        // const rows = keysToInclude.map(key => `${key},${abx[key]}`);
        const rows = telephoneNumberOrder.map(phoneNumber => {
            const errorMessage = abx[phoneNumber.toString()];
            return `${phoneNumber},${errorMessage}`;
          });
        const csv = `${header}\n${rows.join('\n')}`;;


          
          
        // Create a Blob containing the CSV data
        const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
      
        // Trigger the file download using file-saver
        saveAs(blob, 'ExportError.csv');
    }

    const axiosJWT = axios.create();
 
    axiosJWT.interceptors.request.use(async (config) => {
        const currentDate = new Date();
        if (expire * 1000 < currentDate.getTime()) {
            const response = await axios.get(`${baseUrl}/token`);
            config.headers.Authorization = `Bearer ${response.data.accessToken}`;
            setToken(response.data.accessToken);
            const decoded = jwt_decode(response.data.accessToken);
            setName(decoded.name);
            setRole(decoded.role)
            setExpire(decoded.exp);
        }
        return config;
    }, (error) => {
        return Promise.reject(error);
    });

    const checkCancelMessage = () => {
        
        if( 
            (abx.cancel && abx?.bandwidth?.inteliquentAll === 2 && abx?.inteliquent?.inteliquentAll === 2) ||
            (abx?.bandwidth?.inteliquentAll === 2 && !abx?.inteliquent) ||
            (abx?.inteliquent?.inteliquentAll === 2 && !abx?.bandwidth)
        ){
            setCancelFailedMessage(true);
        }
    }

    return (
        <div className="panelbox">
            <Navbar  token={token} access = {access}/>
            <div className="right-panel">
            <Header name={name} />
                <div className="content-page">
                    <div className="common-white-shadow-background setting-section number-thank-you-page">
                        <div className="row">
                           
                        {!abx.show &&<div className="number-thank-img">
                                 <img src="images/correct.png" />
                            </div>}
                            <div className="thank-msg">
                                {abx.order && (abx.carrierType === 0 || abx.carrierType === 1) &&(
                                <>
                                { abx.sansayAll && (abx.inteliquent?.inteliquentAll == 0 || abx.bandwidth?.inteliquentAll == 0) && <h3>Your order has been received.  The Broadband Dynamics team will update you on the status of your order.</h3>} {/* Your order has been completed.*/}
                                {abx.order && !abx.sansayAll && (abx.inteliquent?.inteliquentAll== 0 || abx.bandwidth?.inteliquentAll== 0) && <h3>Your order has been received.  The Broadband Dynamics team will update you on the status of your order.</h3>}
                                {((abx.order && abx.sansayAll && (abx.inteliquent?.inteliquentAll == 1 || abx.bandwidth?.inteliquentAll == 1)) || (abx.order && !abx.sansayAll && (abx.inteliquent?.inteliquentAll == 1 || abx.bandwidth?.inteliquentAll == 1))) && <h3>Your order has been received.  The Broadband Dynamics team will update you on the status of your order.</h3>}
                                {abx.order && !abx.sansayAll && (abx.inteliquent?.inteliquentAll == 2 || abx.bandwidth?.inteliquentAll == 2) && <h3>Your order was not successful.  The Broadband Dynamics team will update you on the status of your order.</h3>}
                                </>)}
                                {abx.order && abx.carrierType === 2 &&(
                                <>
                                
                                { <h3>Your order has been received.  The Broadband Dynamics team will update you on the status of your order.</h3>}                                
                                
                                </>)}
                                {/* {abx.cancel && abx.sansayAll && (abx.inteliquent?.inteliquentAll == 0 || abx.bandwidth?.inteliquentAll == 0) && <h3>Your request has been completed.</h3>}
                                {abx.cancel && !abx.sansayAll && (abx.inteliquent?.inteliquentAll == 0 || abx.bandwidth?.inteliquentAll == 0) && <h3>Your request has been completed.</h3>} */}
                                {/* { abx.cancel && (abx.inteliquent?.inteliquentAll == 0 || abx.bandwidth?.inteliquentAll == 0) && <h3>Your order has been received.  The Broadband Dynamics team will update you on the status of your order.</h3>} 
                                {abx.cancel && !abx.sansayAll && (abx.inteliquent?.inteliquentAll == 2 || abx.bandwidth?.inteliquentAll == 2) && <h3>Your request was not successful.  The Broadband Dynamics team will update you on the status of your request.</h3>}
                                {((abx.cancel && !abx.sansayAll && (abx.inteliquent?.inteliquentAll == 1 || abx.bandwidth?.inteliquentAll == 1)) || (abx.cancel && abx.sansayAll && (abx.inteliquent?.inteliquentAll == 1 || abx.bandwidth?.inteliquentAll == 1))) && <h3>Your request has been received.  The Broadband Dynamics team will update you on the status of your order.</h3>} */}

                                {abx.cancel && cancelFailedMessage && <h3>Your request was not successful.  The Broadband Dynamics team will update you on the status of your request.</h3>}
                                {abx.cancel && !cancelFailedMessage && <h3>Your order has been received. The Broadband Dynamics team will update you on the status of your order.</h3>}
                               
                                {abx.features && abx.cname && (
                                    <>
                                        {((abx.inteliquent?.inteliquentAll === 1 || abx.inteliquent?.inteliquentAll === 0) ||
                                          (abx.bandwidth?.inteliquentAll === 1 || abx.bandwidth?.inteliquentAll === 0)) && (
                                            <h3>Your request has been received. The Broadband Dynamics team will update you on the status of your order.</h3>
                                        )}
                                        {((abx.inteliquent?.inteliquentAll === 2 || abx.bandwidth?.inteliquentAll === 2)) && (
                                            <h3>Your request was not successful. The Broadband Dynamics team will update you on the status of your request.</h3>
                                        )}
                                    </>
                                )}


                                 {abx.features && abx.e911 && (abx.bandwidth?.inteliquentAll === 0) &&  (
                                    
                                    <h3>Your request has been received. The Broadband Dynamics team will update you on the status of your order.</h3>
                                
                            )}
                                {abx.features && abx.e911 && (abx.bandwidth?.inteliquentAll === 2) &&  (
                                    
                                        <h3>Your request was not successful. The Broadband Dynamics team will update you on the status of your request.</h3>
                                    
                                )}
                                {abx.importError && <h3>We have processed your request. Please download the status for each numbers using below button.</h3>}                                
                                {abx.importInventory && <h3>We have processing your import inventory request. You can check import log into <a href="import-inventory-history">Import DID Inventory History</a> Page.</h3>}                                
                                <div>
                                    
                                    {!abx.importError && !abx.importInventory && <table  className="tablebox search-result-table order-result-table">
                                        
                                        <tbody>
                                          <tr>
                                            <td>
                                                <strong>Order Id :- </strong>
                                            </td>
                                            <td>
                                                {abx.orderId}
                                            </td>
                                        </tr>                                             
                                        </tbody>
                                    </table>}
                                </div>
                                
                                {!abx.importInventory &&<Link className="yellow-btn" to={"/number-management"}>Done</Link>}
                                {abx.importError && <a href="#" className='error-btn' onClick={exportErrors}>Export Results</a>}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )

}

export default CreateOrderSuccess